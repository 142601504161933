import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";

// Pages
const HomePage = lazy(() => import("./Pages/HomePage"));
const AboutPage = lazy(() => import("./Pages/About"));
const StockistPage = lazy(() => import("./Pages/Stockists/StockistPage"));
const ContactUsModernPage = lazy(() => import("./Pages/Contact/ContactUsModernPage"));
const CollectionsPage = lazy(() => import("./Pages/Collections/CollectionsPage"));
const CollectionItemPage = lazy(() => import("./Pages/Collections/CollectionItem"));
const ProjectsPage = lazy(() => import("./Pages/Projects/ProjectsPage"));
const ProjectItemPage = lazy(() => import("./Pages/Projects/ProjectItem"));
const NotFoundPage = lazy(() => import("./Pages/404"));


function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<HomePage />} />

                  {/* Blog Routes */}
                  <Route path="/collection/:slug" element={<CollectionsPage />} />
                  <Route path="/projects" element={<ProjectsPage />} />
                  <Route path="/projects/:slug" element={<ProjectItemPage />} />
                  <Route path="/collection/:slug/:itemSlug" element={<CollectionItemPage />} />
                  {/* Services Pages */}
                  <Route path="/stockists" element={<StockistPage style={{ "--base-color": "#0038e3" }} />} />

                  {/* Contact Pages */}
                  <Route path="/contact" element={<ContactUsModernPage style={{ "--base-color": "#0038e3" }} />} />

                  {/* Additional Pages */}
                  <Route path="/page/error-404" element={<NotFoundPage style={{ "--base-color": "#0038e3" }} />} />
                  <Route path="*" element={<NotFoundPage />} />
                  <Route path="/about" element={<AboutPage />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
